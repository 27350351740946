import React from "react";

import { graphql } from "gatsby";

import { useTranslation, Trans } from "gatsby-plugin-react-i18next";

import ClaimsManagementIcon from "assets/icons/wee-claims-management-dark.svg";
import FraudWasteAbuseIcon from "assets/icons/wee-fwa.svg";
import NetworkManagementIcon from "assets/icons/wee-network-blue.svg";
import DataFoundationIcon from "assets/icons/wee-white-df.svg";
import ClaimsManagement from "assets/images/claims-management-dark.svg";
import DataFoundation from "assets/images/data-foundation-dark.svg";
import FraudWasteAbuse from "assets/images/fwa.svg";
import NetworkManagement from "assets/images/network-management.svg";
import Card from "components/Card";
import HalfHero from "components/HalfHero";
import Next from "components/Next";
import PageContainer from "components/PageContainer";
import SEO from "components/SEO";

const ProductPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <HalfHero
        title={
          <Trans t={t} i18nKey="Our Platform">
            Our
            <br />
            Platform
          </Trans>
        }
        description={t(
          "Qantev provides the ideal software platform for modern insurers."
        )}
      />
      <Card
        title="Data Foundation"
        dividerIcon={
          <img src={DataFoundationIcon} alt="Data-foundation-icon" />
        }
        imgSrc={DataFoundation}
        imgAlt="Data-Foundation"
        learnMoreTo="/product/data-foundation"
      >
        {t(
          "Qantev automatically fills missing fields, corrects corrupted data, and computes advanced & complex KPIs in real-time. It enables the user to monitor data quality and the usage of the data to unlock its full potential."
        )}
      </Card>
      <Card
        title="Network Management"
        dividerIcon={
          <img src={NetworkManagementIcon} alt="Netwok-management-icon" />
        }
        imgSrc={NetworkManagement}
        imgAlt="Network-management"
        learnMoreTo="/product/network-management"
        alignImgLeft
      >
        {t(
          "Qantev's AI assesses the entire health care network and recommends the best actions to be performed in real time."
        )}
        <br />
        <br />
        {t(
          "It provides a centralized knowledge base for network manager to track the relationship history with their providers and easily build collaborative action plans to track their impact."
        )}
      </Card>
      <Card
        title="Claims Management"
        dividerIcon={
          <img src={ClaimsManagementIcon} alt="Claims-management-icon" />
        }
        imgSrc={ClaimsManagement}
        imgAlt="Provider-recommender"
        learnMoreTo="/product/claims-management"
      >
        {t(
          "Qantev's solution enables automated coverage & completeness checks and helps claims management team adjudicate low volume high complexity pre-authorisation requests faster than ever while decreasing claim leakage, and saving cost."
        )}
      </Card>
      <Card
        title="Fraud, Waste &amp; Abuse"
        dividerIcon={<img src={FraudWasteAbuseIcon} alt="fwa-icon" />}
        imgSrc={FraudWasteAbuse}
        imgAlt="Fraud-waste-abuse"
        learnMoreTo="/product/fraud-waste-and-abuse"
        alignImgLeft
      >
        {t(
          "Qantev's machine learning models are trained to detect unusual patterns in provider billing, helping clients reduce costs and improve efficiency."
        )}
      </Card>
      <Next
        text={
          <>
            Data
            <br />
            Foundation
          </>
        }
        to="/product/data-foundation"
      />
    </PageContainer>
  );
};

export default ProductPage;

// eslint-disable-next-line react/prop-types
export const Head = ({ location: { pathname } }) => (
  <SEO title="Product" pathname={pathname} />
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["product", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
